import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { config } from '@keytrade/functions';
import { Link as SCLink } from '@keytrade/components-link';

import Title from '@/components/Title';
import {
  SectionTitle,
  PopularTopicsWrapper,
  LinksWrapper,
} from '@/components/support/StyledComponents';
import Page, { DefaultPaths, PageType } from '@/components/Page';
import FAQItem from '@/components/support/FAQItem';
import Link, { createInternalLink, LinkStyle } from '@/components/Link';
import SupportSearch from '@/components/search/SupportSearch';
import BlockWrapper from '@/components/BlockWrapper';
import { addSearchParameter } from '@/utils/URLUtils';
import { useLocalization } from '@/../plugins/keytrade-localization/useLocalization';
import useTranslations from '@/hooks/useTranslations';

const { list: colors } = config.colors;
type Props = PageType & {
  location?: URL & {
    state?: {
      searchValue?: string;
    };
  };
};
const MainThemesWrapper = styled.div`
  margin-bottom: 4rem;
  border-bottom: 1px solid ${colors.BlueLight};

  &:last-child {
    margin-bottom: -1.6rem;
    border-bottom: none;
  }
`;
const MoreWrapper = styled.div`
  margin: 3.2rem 0 4rem;
`;
const SupportHomePageTemplate: React.FC<Props> = (props) => {
  const page = props.data.contentfulPage;
  const content = page.pageType;
  const defaultPaths: DefaultPaths = {};
  defaultPaths.supportPath = props.path;
  const [isSearchingSupport, setIsSearchingSupport] = useState(false);
  const { navigate } = useLocalization();
  const { t, FormattedMessage } = useTranslations();

  return (
    <Page {...props}>
      <SupportSearch
        locale={page.node_locale}
        setParentSearchingSupport={setIsSearchingSupport}
        location={props.location}
      >
        {!isSearchingSupport && (
          <BlockWrapper padding='xsmall'>
            {content && content.popularTopics && (
              <>
                <SectionTitle>
                  <FormattedMessage id='popular_topics' />
                </SectionTitle>

                <PopularTopicsWrapper>
                  {content.popularTopics.map((topic) => (
                    <SCLink
                      key={topic.text}
                      kind='withBackground'
                      onClick={() => {
                        navigate(
                          addSearchParameter(props.path, topic.text),
                          page.node_locale,
                        );
                      }}
                    >
                      {topic.text}
                    </SCLink>
                  ))}
                </PopularTopicsWrapper>
              </>
            )}
            <SectionTitle>
              <FormattedMessage id='frequently_asked_questions' />
            </SectionTitle>
            {content &&
              content.faqMainThemes.map((faqMainTheme) => (
                <MainThemesWrapper key={faqMainTheme.title}>
                  <Title level='h3' size='xs' margin='0 0 2.4rem'>
                    {faqMainTheme.title}
                  </Title>
                  <LinksWrapper>
                    {faqMainTheme.topQuestions &&
                      React.Children.toArray(
                        faqMainTheme.topQuestions.map((itemQuestion) => {
                          return FAQItem({
                            ...itemQuestion,
                            defaultPaths: defaultPaths,
                            margin: '0 0 2rem',
                          });
                        }),
                      )}
                  </LinksWrapper>
                  <MoreWrapper>
                    {faqMainTheme.support_theme_page_type &&
                      faqMainTheme.support_theme_page_type.length &&
                      faqMainTheme.support_theme_page_type[0].page && (
                        <Link
                          {...createInternalLink(
                            faqMainTheme.support_theme_page_type[0].page[0],
                            LinkStyle.SECONDARY,
                            t('more'),
                          )}
                        />
                      )}
                  </MoreWrapper>
                </MainThemesWrapper>
              ))}
          </BlockWrapper>
        )}
      </SupportSearch>
    </Page>
  );
};

export default SupportHomePageTemplate;

export const pageQuery = graphql`
  query ContentfulSupportHomepageQuery(
    $contentfulId: String!
    $locale: String
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      seoMetadata {
        ...SeoMetaDataFragment
      }
      pageType {
        ... on ContentfulSupportHomepageType {
          popularTopics {
            text
          }
          faqMainThemes {
            title
            topQuestions {
              ...FAQItemFragment
            }
            support_theme_page_type {
              page {
                node_locale
                ...PagePathFragment
              }
            }
          }
        }
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
  }
`;
