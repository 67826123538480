import React from 'react';
import { graphql } from 'gatsby';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';
import styled from 'styled-components';

import { Link } from '@keytrade/components-link';
import { Icon } from '@keytrade/components-icon';

import { generatePageTypeSlug } from '@/utils/SlugUtils';
import { DefaultPaths, PageTypeInfoType } from '../Page';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';

export type FAQItemType = {
  title: string;
  page: Array<PageTypeInfoType>;
  defaultPaths: DefaultPaths;
};
type Props = FAQItemType & {
  margin?: string;
  color?: string;
  isSemiBold?: boolean;
  arrowRight?: boolean;
};

type FAQItemWrapperProps = {
  margin: string;
};
const FAQItemWrapper = styled.div<FAQItemWrapperProps>`
  ${({ margin }) => (margin ? `margin:${margin}` : '')};
`;
const FAQItem: React.FC<Props> = (props) => {
  const url = props.page
    ? generatePageTypeSlug(props.page[0], props.defaultPaths)
    : '#';
  return (
    <FAQItemWrapper margin={props.margin}>
      <Link
        color={props.color}
        isSemiBold={props.isSemiBold}
        render={() => (
          <GatsbyLink to={url}>
            <CurrencyFormatter> {props.title}</CurrencyFormatter>
            {props.arrowRight ? <Icon icon='icn_longArrowRight' /> : null}
          </GatsbyLink>
        )}
        size='lg'
      />
    </FAQItemWrapper>
  );
};

export default FAQItem;

export const FAQItemQuery = graphql`
  fragment FAQItemFragment on ContentfulSupportArticle {
    title
    page {
      node_locale
      pageType {
        __typename
      }
      ...PagePathFragment
    }
  }
`;
